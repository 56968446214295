import React,{useState,useEffect} from 'react'
import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes, Route } from 'react-router-dom'
import Auth from "./pages/auth"
import Dashboard from './pages/dashboard'
import Users from './pages/users'
import UserPoints from "./pages/userPoints"
import Reset from './pages/reset'
import Stores from './pages/stores'
import OfflineStores from './pages/offlineStores'
import Winners from './pages/winners'
import StoreDetails from './pages/storeDetails'
import AllTransactionsList from './pages/allTransactions'
import RejectedTransactionsList from './pages/rejectedTransactions'
import PendingTransactionsList from './pages/pendingTransactions'
import ApprovedTransactionsList from './pages/approvedTransactions'
import ReceiptDetails from './pages/receiptDetails'
import ReceiptData from './pages/receiptData'
function App(){
  return(
<div>
<BrowserRouter>
    <Routes>
        <Route path="/" element={<Auth/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/users/:userId" element={<UserPoints />} />
        <Route path="/users" element={<Users/>} />
        <Route path="/stores/:storeId" element={<StoreDetails/>} />
        <Route path="/stores" element={<Stores/>} />
        <Route path="/offline-stores" element={<OfflineStores/>} />
        <Route path="/winners" element={<Winners/>} />
        <Route path="/all-transactions" element={<AllTransactionsList/>} />
        <Route path="/rejected-transactions" element={<RejectedTransactionsList/>} />
        <Route path="/pending-transactions" element={<PendingTransactionsList/>} />
        <Route path="/approved-transactions" element={<ApprovedTransactionsList/>} />
        <Route path="/receipt-details/:receiptId" element={<ReceiptDetails/>} />
        <Route path="/receipt-data/:receiptId" element={<ReceiptData/>} />
        <Route path="/reset" element={<Reset/>} />
    </Routes>
    </BrowserRouter>
</div>

  )
}

export default App;
